<template>
  <div class='row'>
    <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    text-color="#fff"
    active-text-color="#008AFF"
    @select="handleSelect"
    :collapse="false">
      <el-sub-menu index="6">
        <template #title>Menu</template>
          <el-menu-item index="6-0"><router-link to="/">Login</router-link></el-menu-item>
          <el-menu-item index="6-1"><router-link to="/SubirArchivo">Subir archivos</router-link></el-menu-item>
          <el-menu-item index="6-2"><router-link to="/subirpdf">Autorizacion comprobantes</router-link></el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
  <div class="line"></div>
  <router-view/>
</template>
<script>
export default {
  data () {
    return { activeIndex: '1' }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* background-color: #8d8c8c; */
  color: #bbbfc0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.4);
  color: rgb(212, 208, 208);
}
td {
  color: rgb(212, 208, 208);
}
tr.blue th {
  background: #008AFF;
  color: rgb(212, 208, 208);
}
tr.gray td {
  background: gray;
  position: sticky;
  color: rgb(212, 208, 208);
}
</style>
