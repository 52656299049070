<template>
  <div v-if="isLoggedIn" class='container-fluid'>
    <br/>
    <back/>
    <br/>
    <form class = 'form-inline' role='form' @submit.prevent='getTicket'>
      <div class="row">
        <div class="col-md-3">
          <!-- <span style='padding-right: 10px;'>Rango de fechas</span> -->
        <el-date-picker
          v-model="value1"
          type="daterange"
          unlink-panels
          range-separator="|"
          start-placeholder="Desde"
          end-placeholder="Hasta"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
        </div>
        <div class="col-md-2">
          <el-select v-model="estado" filterable clearable placeholder="Estado autorizacion">
            <el-option
              v-for="item in estados"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </div>
          <div class="col-md-3">
          <el-select v-model="proveedor" filterable clearable placeholder="Proveedores">
            <el-option
              v-for="item in proveedores"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2">
        Empresa
        <el-select v-model="empresa" filterable disabled clearable placeholder="Empresa">
          <el-option
            v-for="item in empresas"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        </div>
        <div class="col-md-2">
          <button type='submit' class='btn btn-primary mb-2'>
            Recuperar
          </button>
        </div>
      </div>
    </form>
    <div class='row'>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
      </div>
    </div><br>
    <div class='row'>
      <div class="col-md-7">
        <div class="form-inline">
        <el-input type="search" placeholder="Filtrar por" v-model='search' id = 'isearch'/>
      </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <div class="form-check-inline">
              <input type="checkbox" v-model="nroctaF">Nro Cta
          </div>
          <div class="form-check-inline">
              <input type="checkbox" v-model="codforF">Cod Formulario
          </div>
          <div class="form-check-inline">
              <input type="checkbox" v-model="codoriF">Codigo Original
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class='row'>
    </div><br>
    <div class='row'>
      <div id='message' div class='col-md-12'>
        <table id="table_id"
              class="table table-bordered table-condensed vue-table"
              data-filter-control="true"
              data-search='true'
              >
          <thead>
            <tr class = 'blue'>
                <th>#</th>
                <!-- <th>Mod For</th>
                <th>Cod For</th>
                <th>Nro For</th> -->
                <th>Fec Mov</th>
                <th>Proveedor</th>
                <th>Codigo original</th>
                <th>Adjuntos</th>
                <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(item, index) in filtered' :key='item.ticket'>
              <td>{{index + 1}}</td>
              <!-- <td v-html="highlightMatches(item.pvrmvhModfor)"></td>
              <td v-html="highlightMatches(item.pvrmvhCodfor)"></td>
              <td v-html="item.pvrmvhNrofor"></td> -->
              <td v-html="highlightMatches(item.pvrmvhFchmov)"></td>
              <td v-html="highlightMatches(item.pvmprhNombre)"></td>
              <td v-html="highlightMatches(item.pvrmvhCodori)"></td>
              <td >
                <unicon v-if = "item.adjunto=='S'" name="paperclip" fill="royalblue"></unicon>
              </td>
              <td>
                <el-button type="primary" icon="el-icon-search" v-on:click="this.verComprobante(item.pvrmvhCodfor, item.pvrmvhNrofor, item.usrNombre)">Ver</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import config from '../../public/config.json'
import back from '../components/back.vue'
export default {
  name: 'imp-list-row',
  components: {
    back
  },
  beforeMount () {
  },
  mounted: function () {
    if (localStorage.getItem('pvrmvh')) {
      try {
        var self = this
        self.pvrmvh = JSON.parse(localStorage.getItem('pvrmvh'))
        self.codforF = localStorage.getItem('codforF')
        self.nroctaF = localStorage.getItem('nroctaF')
        self.codoriF = localStorage.getItem('codoriF')
        self.value1 = [localStorage.getItem('fromSubirPdf'), localStorage.getItem('toSubirPdf')]
      } catch (e) {
        localStorage.removeItem('pvrmvh')
      }
      if (localStorage.getItem('searchCpr')) {
        self.search = localStorage.getItem('searchCpr')
      }
      if (localStorage.getItem('estado')) {
        self.estado = localStorage.getItem('estado')
      } else {
        self.estado = ''
      }
    }
  },
  watch: {
    value1 (newVal) {
      if (newVal) {
        localStorage.setItem('fromSubirPdf', newVal[0])
        localStorage.setItem('toSubirPdf', newVal[1])
      } else {
        localStorage.setItem('fromSubirPdf', null)
        localStorage.setItem('toSubirPdf', null)
      }
    },
    search (newVal) {
      localStorage.setItem('searchCpr', newVal)
    },
    estado (newVal) {
      localStorage.setItem('estado', newVal)
      this.estado = newVal
    }
  },
  data: function () {
    return {
      pendUrlFormat: '',
      pendiente: false,
      value1: '',
      loading: '',
      search: '',
      pvrmvh: [],
      codforF: true,
      nroctaF: true,
      codoriF: true,
      empresas: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'COMPC',
        label: 'COMPC'
      }, {
        value: 'COMCAM',
        label: 'COMCAM'
      }, {
        value: 'CAVASUR',
        label: 'CAVASUR'
      }, {
        value: 'FIDEICOMISO',
        label: 'FIDEICOMISO'
      }, {
        value: 'PETROCOM',
        label: 'PETROCOM'
      }, {
        value: 'SURCANUELAS',
        label: 'SURCANUELAS'
      }, {
        value: 'UNITEC',
        label: 'UNITEC'
      }],
      empresa: 'COMCAM',
      estados: [{
        value: 'S',
        label: 'Autorizado'
      }, {
        value: 'N',
        label: 'Rechazado'
      }, {
        value: 'P',
        label: 'Pendiente'
      }],
      estado: '',
      proveedores: [],
      proveedor: ''
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filtered () {
      return this.pvrmvh
        .filter(this.filterById)
    }
  },
  methods: {
    // getProveedores: function (e) {asdasdasd
    //   var self = this
    //   self.openFSL()
    //   const url = 'http://' + config.ip + '/ticket/profesional/'
    //   axios.get(url, {
    //     dataType: 'json',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: self.getToken.token
    //     },
    //     mode: 'no-cors',
    //     credentials: 'include'
    //   })
    //     .then(function (response) {
    //       localStorage.setItem('asignados', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
    //       self.asignados = JSON.parse(localStorage.getItem('asignados'))
    //       self.closeFSL()
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //       self.closeFSL()
    //     })
    // },
    verComprobante (codigo, numero, nombre) {
      localStorage.setItem('codfor', codigo)
      localStorage.setItem('nrofor', numero)
      localStorage.setItem('nombre', nombre)
      this.$router.push('/pdf')
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterById (imp) {
      var keep = true
      if (this.search.length !== 0) {
        keep = false
        if (this.nroctaF) {
          keep = (imp.pvrmvhNrocta + ' - ' + imp.pvmprhNombre).toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
        }
        if (this.codforF && !keep) {
          keep = imp.pvrmvhCodfor.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
        }
        if (this.codoriF && !keep) {
          if (imp.pvrmvhCodori) {
            keep = imp.pvrmvhCodori.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
          }
        }
      }
      return keep
    },
    filterByrecurrente (imp) {
      if (this.recurrente === false) return true
      return (imp.estado.toString().toLowerCase() === 's' && this.recurrente)
    },
    getTicket: function (e) {
      var self = this
      self.openFSL()
      var desde = self.strToDate('1900-01-01')
      var hasta = self.strToDate('9999-12-31')
      if (self.value1 && self.value1.toString().indexOf('null') < 0) {
        desde = self.strToDate(self.value1[0])
        hasta = self.strToDate(self.value1[1])
      }
      const url = 'http://' + config.ip + '/autfis/pvrmvh/pendiente/params;autorizado=' + self.estado + ';desde=' + desde + ';hasta=' + hasta
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('pvrmvh', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          localStorage.setItem('codforF', self.codforF)
          localStorage.setItem('nroctaF', self.nroctaF)
          localStorage.setItem('codoriF', self.codoriF)
          self.pvrmvh = JSON.parse(localStorage.getItem('pvrmvh'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    strToDate (str) {
      if (!str) {
        str = '1900-01-01'
        str = '1900-01-01T00:00:00'
      }
      // str = '1900-01-01T00:00:00'
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    }
  }
}
</script>
<style>
  th {
  background: black;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
tr.blue th {
  background: #0d6efd;
  color: white;
}
tr.gray td {
  background: gray;
  color: white;
}
</style>
