import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import ElementPlus from 'element-plus'
import Unicon from 'vue-unicons'
import { uniPaperclip } from 'vue-unicons/dist/icons'
import 'element-plus/dist/index.css'
import 'element-theme-dark'
import VueApexCharts from 'vue3-apexcharts'
Unicon.add([uniPaperclip])
const app = createApp(App)
app.use(Unicon)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VueApexCharts)
app.mount('#app')
