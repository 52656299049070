<template>
   <div>
     <div class="row">
       <div class="col-md-5"></div>
        <div class="col-md-2">
          <datepicker placeholder="Select Date"></datepicker>
          <h1>Ingresar</h1>
          <form @submit.prevent='login'>
            <el-input v-model='username' placeholder='usuario' />
            <br />
            <br />
            <el-input v-model='password' placeholder='clave' type='password' />
            <br />
            <br />
            <button type='submit' class='btn btn-primary mb-2'>Login</button>
          </form>
        </div>
        <div class="col-md-5"></div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import shared from '../shared/shared'
import config from '../../public/config.json'
export default {
  extends: shared,
  data: () => {
    return {
      username: '',
      password: ''
    }
  },
  beforeMount () {
    if (this.$route.query.jwt) {
      this.setToken(this.$route.query.jwt)
      this.$router.push('/Home')
      // request jwt check
      // devuelve user, area, jwt y estado del token
      // si estado es ok entonces ir a la url del parametro
      // sino mostrar pantalla login
    }
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setArea']),
    async login (e) {
      console.log(config.ip)
      e.preventDefault()
      // 104.154.67.132:8081
      fetch('http://' + config.ip + '/login/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          usrNombre: this.username,
          usrClave: this.password
        })
      })
        .then(async response => {
          const { user, area, JWT } = await response.json()
          this.setUser(user)
          this.setArea(area)
          this.setToken(JWT)
          this.successMsg('Acceso Correcto, bienvenido ' + user)
          if (area === 'logistica' || user === 'clemente') {
            this.$router.push('/variablesLarga')
          } else {
            if (this.$router.options.history.state.back) {
              if (this.$router.options.history.state.back.toString().substring(0, 1) === '/') {
                this.$router.go(-1)
              } else {
                this.$router.push('/home')
              }
            } else {
              this.$router.push('/home')
            }
          }
        })
        .catch(error => {
          this.setToken(null)
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
        })
    }
  }
}
</script>
<style scoped>
body
{
    margin:auto;
    width:1024px;
    padding:10px;
    background-color:#4d3737;
    font-size:14px;
    font-family:Verdana;
}
</style>
