import { createRouter, createWebHistory } from '@ionic/vue-router'
import Login from '../views/Login.vue'
import SubirPdf from '../views/SubirPdf.vue'
import SubirArchivo from '../views/SubirArchivo.vue'
import Pdf from '../views/Pdf.vue'
import ViewPdf from '../views/ViewPdf.vue'

const routes = [
  {
    path: '/pdf',
    name: 'Pdf',
    component: Pdf
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/subirpdf',
    name: 'SubirPdf',
    component: SubirPdf
  },
  {
    path: '/subirarchivo',
    name: 'SubirArchivo',
    component: SubirArchivo
  },
  {
    path: '/ViewPdf',
    name: 'ViewPdf',
    component: ViewPdf
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
