<template>
<div v-if="isLoggedIn">
  <br/>
  <back/>
  <br/>
  <div class='row'>
    <div div class='col-md-12'>
      <h4>Registro fisico</h4>
      <div div class='col-md-6'>
        <input @change='handleImage' type = 'file' id = 'uploadFile' accept='application/pdf, image/png' style="display:none">
        <el-button type="primary" @click='upload' v-if="this.getArea.area==='administracion'">Seleccionar archivo</el-button>
        <el-button type="primary" @click='subir' v-if="this.getArea.area==='administracion'">Subir</el-button>
        <el-button type="primary" @click='upload' v-if="this.getArea.area==='it'">Seleccionar archivo</el-button>
        <el-button type="primary" @click='subir' v-if="this.getArea.area==='it'">Subir</el-button>
      </div>
      <br/>
      <vue-pdf-embed :source="image" name="plugin2" v-if="image.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="image" type="image/png" v-if="!image.includes('pdf;')">
    </div>
  </div>
  <br/>
  <el-divider></el-divider>
  <div class='row'>
    <div class="col-md-12">
      <div class='row' v-if="mostrar=='S'">
        <div class="col-md-12">
          <el-radio-group v-model="radio1">
            <el-radio border v-model="radio1" label="S" :disabled="!this.autorizacion[0] || this.autorizacion[0].usrAutorizado !== 'P'">Autorizado</el-radio>
            <el-radio border v-model="radio1" label="N" :disabled="!this.autorizacion[0] || this.autorizacion[0].usrAutorizado !== 'P'">Rechazado</el-radio>
            <el-radio border v-model="radio1" label="" :disabled="!this.autorizacion[0] || this.autorizacion[0].usrAutorizado !== 'P'">Sin definir</el-radio>
            <el-button type="primary" @click="this.persistAUT('PUT')" :disabled="!this.autorizacion[0] || this.autorizacion[0].usrAutorizado !== 'P'">Confirmar</el-button>
          </el-radio-group>
        </div>
      </div>
      <br/>
      <div class='row' v-if="mostrar=='N'">
        <el-input v-model='valoringresado' placeholder='ingrese importe total' v-on:keypress="this.isNumber(event)" />
      </div>
      <div class='row' v-if="mostrar=='N'">
        <el-button type="primary" @click="mostrarERP()">mostrar</el-button>
      </div>
      <div class='row' v-if="mostrar=='S'">
        <div class="col-md-12">
          <el-collapse v-model="header" accordion>
            <el-collapse-item title="Registro ERP" name="1">
              <h4> Registro ERP </h4>
                    <div class='row' v-if="mostrar=='S'">
                      <div class="col-md-3">
                        <div class="form-group">
                          <strong>Empresa:</strong> {{pvrmvhSelected.pvrmvhCodemp}}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <strong>Formulario: </strong>{{pvrmvhSelected.pvrmvhPK.pvrmvhCodfor}}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                        <strong>Numero:</strong> {{pvrmvhSelected.pvrmvhPK.pvrmvhNrofor}}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <strong>Cpr Original:</strong> {{pvrmvhSelected.pvrmvhCodori}}
                        </div>
                      </div>
                    </div>
                    <div class='row' v-if="mostrar=='S'">
                      <div class="col-md-6">
                          <div><strong>Proveedor:</strong>
                          <!-- {{pvrmvhSelected.pvmprh.pvmprhNrocta}} - {{pvrmvhSelected.pvmprh.pvmprhNombre}} -->
                          </div>
                          <div><strong>Direccion:</strong> {{pvrmvhSelected.pvmprh.pvmprhDirecc}} (CP {{pvrmvhSelected.pvmprh.pvmprhCodpos}})
                          </div><strong>Telefono:</strong> {{pvrmvhSelected.pvmprh.pvmprhTelefn}}
                          <strong>Tipo / Nro Documento:</strong> {{pvrmvhSelected.pvmprh.pvmprhTipdoc}} / {{pvrmvhSelected.pvmprh.pvmprhNrodoc}}
                      </div>
                      <div class="col-md-6">
                        <strong>Emision:</strong> {{pvrmvhSelected.pvrmvhFchemi}}<br/>
                        <strong>Contabilizacion:</strong> {{pvrmvhSelected.pvrmvhFchmov}}<br/>
                        <strong>Recepcion:</strong> {{pvrmvhSelected.pvrmvhFchrec}}
                      </div>
                    </div>
                    <div class='row' v-if="mostrar=='S'">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6">
                          <strong>Cnd Pago:</strong> {{pvrmvhSelected.pvrmvhCndpag}}
                        </div>
                    </div>
                    <div class='row' v-if="mostrar=='S'">
                      <div class="col-md-12">
                        <h3>Items</h3>
                        <table id='table_id'
                          class='table table-bordered table-condensed vue-table'
                          >
                          <thead>
                            <tr>
                                <th>#</th>
                                <th>Tipo Concepto</th>
                                <th>Concepto</th>
                                <th>Articulo</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Importe</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for='item in pvrmvhSelected.pvrmvi' :key='item.pvrmviPK.pvrmviNroitm'>
                              <td><span v-if="item.pvrmviTipcpt !== 'T'">{{item.pvrmviPK.pvrmviNroitm}}</span></td>
                              <td><span v-if="item.pvrmviTipcpt !== 'T'">{{item.pvrmviTipcpt}}</span></td>
                              <td><span v-if="item.pvrmviTipcpt !== 'T'">{{item.pvrmviCodcpt}}</span></td>
                              <td><span v-if="item.stmpdh">{{item.stmpdh.stmpdhDescrp}}</span></td>
                              <td><span v-if="item.pvrmviCantid > 0">{{item.pvrmviCantid}}</span></td>
                              <td><span v-if="item.pvrmviPrenac > 0">{{currencyFormat(item.pvrmviPrenac)}}</span></td>
                              <td><span v-if="item.pvrmviImpnac > 0">{{currencyFormat(item.pvrmviImpnac)}}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
            </el-collapse-item>
            <el-collapse-item title="Estado autorizaciones" name="2">
              <table id='table_auditoria'
                class='table table-bordered table-condensed  vue-table'
                >
                <thead>
                  <tr>
                      <th>#</th>
                      <th>Usuario</th>
                      <th>Nivel</th>
                      <th>Autorizado</th>
                      <th>Instante</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in this.auditoria" :key="item.usrWwwAutaudPK.usrNivel">
                    <td>{{ index + 1 }}</td>
                    <td><span>{{item.usrUsuario}}</span></td>
                    <td><span>{{item.usrWwwAutaudPK.usrNivel}}</span></td>
                    <td><span>{{item.usrAutorizado}}</span></td>
                    <td><span v-if="item.usrInstante.substring(0, 4) != '1900'">{{item.usrInstante.replace('T',' ').replace('Z[-03:00]','')}}</span></td>
                  </tr>
                </tbody>
              </table>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import router from '../router'
import { ref } from 'vue'
import back from '../components/back.vue'
import shared from '../shared/shared'
import axios from 'axios'
import config from '../../public/config.json'
import { mapGetters } from 'vuex'
import { ElMessageBox, ElMessage } from 'element-plus'
import VuePdfEmbed from 'vue-pdf-embed'
export default {
  extends: shared,
  components: {
    back, VuePdfEmbed
  },
  beforeMount () {
    this.mostrar = 'N'
    this.adjunto = 'N'
    this.codfor = localStorage.getItem('codfor')
    this.nrofor = localStorage.getItem('nrofor')
    this.getCpr(this.codfor, this.nrofor)
    this.getAut(this.codfor, this.nrofor)
    this.getFisico(this.codfor, this.nrofor)
  },
  mounted: function () {
  },
  data: function () {
    return {
      fileSelected: '',
      header: 1,
      autorizacion: [],
      auditoria: [],
      valoringresado: '',
      adjunto: 'N',
      codfor: '',
      nrofor: 0,
      nivel: 0,
      pvrmvhSelected: [],
      pvrmvi: [],
      radio1: ref(''),
      image: '',
      mostrar: ''
    }
  },
  name: 'Home',
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea'])
  },
  methods: {
    persistAUT (pMethod) {
      var pBody = JSON.stringify({
        usrWwwAutaudPK: { usrModfor: 'PV', usrCodfor: this.codfor, usrNrofor: this.nrofor, usrNivel: this.nivel },
        usrAutorizado: this.radio1
      })
      var pUrl = 'http://' + config.ip + '/autfis/usrwwwautaud/'
      this.persist(pUrl, pMethod, pBody)
    },
    async persist (pUrl, pMethod, pBody) {
      this.openFSL(this)
      fetch(pUrl, {
        method: pMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: pBody
      })
        .then(async response => {
          this.successMsg('Operacion exitosa')
          this.closeFSL(this)
          router.go(-1)
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL(this)
        })
    },
    upload () {
      document.getElementById('uploadFile').click()
    },
    mostrarERP () {
      var self = this
      self.pvrmvhSelected.pvrmvi.forEach(function (item) {
        if (item.pvrmviTipcpt === 'T') {
          if (item.pvrmviImpnac === Number(self.valoringresado)) {
            self.mostrar = 'S'
          } else {
            ElMessage({
              message: 'Atencion, verifique importe ingresado',
              type: 'warning'
            })
          }
        }
      }
      )
    },
    getCpr: function (codigo, numero) {
      var self = this
      self.openFSL(self)
      const url = 'http://' + config.ip + '/autfis/pvrmvh/id/id;pvrmvhModfor=' + 'PV' + ';pvrmvhCodfor=' + codigo + ';pvrmvhNrofor=' + numero
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('pvrmvhSelected', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.pvrmvhSelected = JSON.parse(localStorage.getItem('pvrmvhSelected'))
          self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL(self)
        })
    },
    getAut: function (codigo, numero) {
      var self = this
      self.openFSL(self)
      var url = 'http://' + config.ip + '/autfis/usrwwwautaud/pend/pend;usrModfor=' + 'PV' + ';usrCodfor=' + codigo + ';usrNrofor=' + numero
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          self.autorizacion = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.radio1 = ref(self.autorizacion[0].usrAutorizado ? self.autorizacion[0].usrAutorizado : '')
          self.nivel = self.autorizacion[0].usrWwwAutaudPK.usrNivel
          self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL(self)
        })
      url = 'http://' + config.ip + '/autfis/usrwwwautaud/audit/audit;usrModfor=' + 'PV' + ';usrCodfor=' + codigo + ';usrNrofor=' + numero
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          self.auditoria = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL(self)
        })
    },
    subir () {
      // si ya tenia adjunto hacer PUT
      if (this.adjunto === 'S') {
        ElMessageBox.confirm(
          'esta a punto de reemplazar una imagen, desea continuar?',
          'Atencion',
          {
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            type: 'warning'
          }
        )
          .then(() => {
            this.persistirRepo('PUT')
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: 'Operacion Cancelada'
            })
          })
      } else {
        this.persistirRepo('POST')
      }
    },
    getFisico (codfor, nrofor) {
      var self = this
      var url = 'http://' + config.ip + '/autfis/usrrepocpr/id;usrModfor=' + 'PV' + ';usrCodfor=' + codfor + ';usrNrofor=' + nrofor + ';usrNombre=' + localStorage.getItem('nombre')
      this.openFSL(this)
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          if (response.data[0].usrContenido) {
            self.adjunto = 'S'
            self.image = response.data[0].usrContenido
          }
          self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL(self)
        })
    },
    async persistirRepo (method) {
      var self = this
      this.openFSL(this)
      var url = 'http://' + config.ip + '/autfis/usrrepocpr'
      fetch(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: JSON.stringify({
          // usrRepoCprPK: {
          usrCodfor: this.pvrmvhSelected.pvrmvhPK.pvrmvhCodfor,
          usrModfor: this.pvrmvhSelected.pvrmvhPK.pvrmvhModfor,
          usrNombre: this.fileSelected,
          usrNrofor: this.pvrmvhSelected.pvrmvhPK.pvrmvhNrofor,
          // },
          usrContenido: this.image
        })
      })
        .then(async response => {
          this.successMsg('Operacion exitosa')
          this.closeFSL(self)
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL(self)
        })
    },
    volver () {
      router.push('/subirpdf')
    },
    currencyFormat (val) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(val)
    },
    handleImage (e) {
      const selectedImage = e.target.files[0]
      this.fileSelected = selectedImage.name
      // console.log(selectedImage.name)
      this.createBase64Image(selectedImage)
    },
    createBase64Image (fileObject) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.image = reader.result
      }
      reader.readAsDataURL(fileObject)
    }
  }
}
</script>
