<template>
  <div v-if="isLoggedIn" class='container-fluid'>
    <br/>
    <back/>
    <br/>
    <form class = 'form-inline' role='form' @submit.prevent='getTicket'>
      <div class="row">
        <div class="col-md-3">
        <el-input type="search" placeholder="Nombre archivo" v-model='nombre' id = 'nombre'/>
        </div>
        <div class="col-md-4">
          <el-select v-model="proveedor" filterable clearable placeholder="Proveedores">
            <el-option
              v-for="item in proveedores"
              :key="item.pvmprhNrocta"
              :label="item.pvmprhNombre"
              :value="item.pvmprhNrocta">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2">
        </div>
        <div class="col-md-2">
          <button type='submit' class='btn btn-primary mb-2'>
            Recuperar
          </button>
        </div>
      </div>
    </form>
    <div class='row'>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
      </div>
    </div><br>
    <div class='row'>
      <div class="col-md-7">
        <div class="form-inline">
        <el-input type="search" placeholder="Filtrar por" v-model='search' id = 'isearch'/>
      </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <div class="form-check-inline">
              <input type="checkbox" v-model="nroctaF">Nro Cta
          </div>
          <div class="form-check-inline">
              <input type="checkbox" v-model="codforF">Cod Formulario
          </div>
          <div class="form-check-inline">
              <input type="checkbox" v-model="codoriF">Codigo Original
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class='row'>
    </div><br>
    <div class='row'>
      <div class="col-md-8">
      </div>
      <div class="col-md-4">
        <el-button size="mini" type="success" icon="el-icon-plus" @click="mostrarIMPU('A')" plain>nuevo archivo</el-button>
      </div>
    </div>
    <br/>
    <div class='row'>
      <div id='message' div class='col-md-12'>
        <table id="table_id"
              class="table table-bordered table-condensed vue-table"
              data-filter-control="true"
              data-search='true'
              >
          <thead>
            <tr class = 'blue'>
                <th>#</th>
                <th>Nombre archivo</th>
                <th>Proveedor</th>
                <th>Mod For</th>
                <th>Cod For</th>
                <th>Nro For</th>
                <th>Codigo original</th>
                <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(item, index) in filtered' :key='item.usrNombre'>
              <td>{{index + 1}}</td>
              <td v-html="highlightMatches(item.USR_NOMBRE)"></td>
              <td v-html="highlightMatches(item.USR_PROVEEDOR)"></td>
              <td v-html="highlightMatches(item.USR_MODFOR)"></td>
              <td v-html="highlightMatches(item.USR_CODFOR)"></td>
              <td v-html="item.USR_NROFOR"></td>
              <td v-html="highlightMatches(item.USR_CODORI)"></td>
              <td>
                <el-button type="primary" icon="el-icon-search" @click="this.visualizar(item.USR_NOMBRE, item.USR_CODFOR, item.USR_NROFOR)">Ver</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import config from '../../public/config.json'
import back from '../components/back.vue'
export default {
  name: 'imp-list-row',
  components: {
    back
  },
  beforeMount () {
    this.getProveedores()
  },
  mounted: function () {
    var self = this
    if (localStorage.getItem('UsrRepoCpr')) {
      try {
        self.UsrRepoCpr = JSON.parse(localStorage.getItem('UsrRepoCpr'))
      } catch (e) {
        localStorage.removeItem('UsrRepoCpr')
      }
    }
    if (localStorage.getItem('proveedores')) {
      try {
        self.proveedores = JSON.parse(localStorage.getItem('proveedores'))
      } catch (e) {
        localStorage.removeItem('proveedores')
      }
    }
  },
  watch: {
    value1 (newVal) {
      if (newVal) {
        localStorage.setItem('fromSubirPdf', newVal[0])
        localStorage.setItem('toSubirPdf', newVal[1])
      } else {
        localStorage.setItem('fromSubirPdf', null)
        localStorage.setItem('toSubirPdf', null)
      }
    },
    search (newVal) {
      localStorage.setItem('searchCpr', newVal)
    },
    estado (newVal) {
      localStorage.setItem('estado', newVal)
      this.estado = newVal
    }
  },
  data: function () {
    return {
      nombre: '',
      pendUrlFormat: '',
      pendiente: false,
      value1: '',
      loading: '',
      search: '',
      UsrRepoCpr: [],
      codforF: true,
      nroctaF: true,
      codoriF: true,
      proveedores: [],
      proveedor: ''
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filtered () {
      return this.UsrRepoCpr
        .filter(this.filterById)
    }
  },
  methods: {
    getProveedores: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/autfis/pvmprh'
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('proveedores', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.proveedores = JSON.parse(localStorage.getItem('proveedores'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    visualizar (nombre, codfor, nrofor) {
      localStorage.setItem('codfor', codfor)
      localStorage.setItem('nrofor', nrofor)
      localStorage.setItem('nombre', nombre)
      this.$router.push('/ViewPdf')
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterById (imp) {
      return true
      // var keep = true
      // if (this.search.length !== 0) {
      //   keep = false
      //   if (this.nroctaF) {
      //     keep = (imp.pvrmvhNrocta + ' - ' + imp.pvmprhNombre).toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
      //   }
      //   if (this.codforF && !keep) {
      //     keep = imp.pvrmvhCodfor.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
      //   }
      //   if (this.codoriF && !keep) {
      //     if (imp.pvrmvhCodori) {
      //       keep = imp.pvrmvhCodori.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
      //     }
      //   }
      // }
      // return keep
    },
    filterByrecurrente (imp) {
      if (this.recurrente === false) return true
      return (imp.estado.toString().toLowerCase() === 's' && this.recurrente)
    },
    getTicket: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/autfis/usrrepocpr/params/id;nombre=' + self.nombre + ';proveedor=' + self.proveedor
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('UsrRepoCpr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.UsrRepoCpr = JSON.parse(localStorage.getItem('UsrRepoCpr'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    strToDate (str) {
      if (!str) {
        str = '1900-01-01'
        str = '1900-01-01T00:00:00'
      }
      // str = '1900-01-01T00:00:00'
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    }
  }
}
</script>
<style>
  th {
  background: black;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
tr.blue th {
  background: #0d6efd;
  color: white;
}
tr.gray td {
  background: gray;
  color: white;
}
</style>
